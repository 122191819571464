<footer class="">
  <div class="footer2">
    <div class="area-container2">
      <div class="container-links">
        <div class="area-link mobile">
          <div class="title2">SOLUÇÕES</div>
          <a class="link" href="/azulzinha-caixa-tem">CAIXA Tem</a>
          <a class="link" routerLink="/maquininhas">Maquininhas</a>
          <a class="link" routerLink="/vendas-online">Vendas Online</a>
          <div class="title2 mt-4">SERVIÇOS</div>
          <a class="link" routerLink="/antecipacao-de-vendas">Antecipação de vendas</a>
          <a class="link" routerLink="/bandeiras-vouchers"
            >Bandeiras e Vouchers</a
          >
          <a class="link" routerLink="/crediario-azulzinha"
            >Crediário</a
          >
          <a class="link" routerLink="/lotericas">Lotéricas</a>
          <a class="link" routerLink="/pagamento-parcial">Pagamento Parcial</a>
          <a class="link" routerLink="/pagamentos-whatsapp">Pagamentos por WhatsApp</a>
          <a class="link" routerLink="/pix-azulzinha">Pix</a>
          <a class="link" routerLink="/recarga-de-celulares">Recarga</a>
        </div>
        <div class="area-link mobile">
          <div class="title2">GESTÃO DO SEU NEGÓCIO</div>
          <a class="link" routerLink="/app">App da azulzinha</a>
          <a class="link" routerLink="/portal">Portal da azulzinha</a>
          <div class="title2 mt-4">
            <a style="color: #005faf" routerLink="/blog">BLOG</a>
          </div>
          <div class="title2 mt-4">SUPORTE</div>
          <a class="link" href="/ajuda">Ajuda</a>
          <a class="link" routerLink="/aviso-de-cookies">Aviso de Cookies</a>
          <a
            class="link"
            href="{{
              env.origin
            }}/midias/Fiserv_Contrato_de_Adesao_e_Credenciamento_4_RTD_SP.pdf"
            target="_blank"
            download
            >Contrato de Credenciamento azulzinha</a
          >
          <a
            class="link"
            href="{{
              env.origin
            }}/midias/termo-condicoes-complementares-CAIXA_Tem.pdf"
            target="_blank"
            download
          >
            Contrato de Credenciamento CAIXA Tem</a
          >
          <a class="link" routerLink="/termo-lgpd"> LGPD</a>
          <a class="link" routerLink="/politica-de-privacidade"
            >Política de Privacidade</a
          >
          <a class="link" routerLink="/maquinas-e-taxas">Taxas</a>
        </div>

        <div class="area-link mobile">
          <div class="title2">CENTRAL DE RELACIONAMENTO</div>
          <div>
            <div class="d-flex mt-3 align-items-center gap-2">
              <a target="_blank" href="https://wa.me/5511941473336"
                ><img
                  style="max-width: 20px"
                  [lazyLoad]="'assets/img/wpp-icon.png'"
                  alt=""
              /></a>
              <a
                class="numero"
                target="_blank"
                href="https://wa.me/5511941473336"
                >(11) 94147-3336</a
              >
            </div>
            <p class="text-horas mb-3">
              Segunda a Sábado das 08h00 às 20h00 (exceto feriados)
            </p>
          </div>

          <a href="tel:+551130035365" style="margin: 0" class="numero"
            >3003 5365</a
          >
          <a style="margin: 0" class="link mb-2">Capital e Grande São Paulo </a>
          <a href="tel:08007295365" class="numero mb-0">0800 729 5365 </a>
          <a style="margin: 0" class="link">Demais localidades </a>
          <p class="text-horas">
            Segunda a Domingo das 08h00 às 22h00 (exceto feriados)
          </p>

          <div class="texto-mobile">
            <div class="title2 mt-4">OUVIDORIA</div>
            <a href="tel:0800 727 4655" class="numero">0800 727 4655 </a>
            <p class="text-horas">
              Segunda a Sexta das 09h00 às 18h00 (exceto feriados)
            </p>
          </div>
        </div>
        <div class="d-none area-link mobile">
          <div class="title2">OUVIDORIA</div>
          <a href="tel:0800 727 4655" class="numero">0800 727 4655 </a>
          <p class="text-horas">
            Segunda a Sexta das 09h00 às 18h00 (exceto feriados)
          </p>
        </div>
      </div>
      <div class="area-logo">
        <div class="logo-caixa">
          <img
            class="img-caixa"
            [lazyLoad]="'assets/img/caixa-cartoes-logo.png'"
            alt=""
          />
        </div>
        <div class="logo-fiserv">
          <img
            class="img-fiserv"
            [lazyLoad]="'assets/img/logo-footer-fiserv.png'"
            alt=""
          />
        </div>
      </div>
      <div class="text-center mt-5">
        <p class="link">
          © 2024 Fiserv do Brasil Instituição de Pagamento Ltda. Todos os
          direitos reservados.
        </p>
      </div>
    </div>
  </div>
</footer>
